import { RE2JS } from 're2js';

import {
  ALLOWED_URL_PATTERNS,
  ALLOWED_URL_PATTERNS_WITH_REGULAR_ID,
  ID_PATTERN,
  UUID_PATTERN,
} from './allowedUrlPatterns';

type DynamicParams = Record<string, string | number | boolean | undefined>;

export const formatAdditionalAxiosParams = (params: DynamicParams) => {
  if (!params) return '';

  return Object.entries(params)
    .map(([key, _]) => `&${key}=.*`)
    .join('');
};

export function validateUrl(url: string | undefined) {
  if (url === undefined) return false;

  const normalizedUrl = url.replace(/^https?:\/\/[^\/]+/, '');

  const regexPatterns = ALLOWED_URL_PATTERNS.map((pattern) => {
    const regexPathPattern = pattern.replace(/:uuid/g, UUID_PATTERN);
    const [patternPath, patternQuery] = regexPathPattern.split('?');

    const regexPath = `^${patternPath}`;
    const regexQuery = patternQuery
      ? `\\?${patternQuery
          .split('&')
          .map((param, index) => {
            const repeatingMatch = param.match(/^\(([^=]+=[^&]*)\)\+$/);
            if (repeatingMatch) {
              const paramName = repeatingMatch[1].split('=')[0];

              return `(?:&${paramName}=[^&]*)+`;
            }

            const isOptional = param.startsWith('(') && param.endsWith(')');
            const paramName = isOptional ? param.slice(1, -1) : param;

            const name = paramName.split('=')[0];

            if (isOptional) {
              return `(?:&${name}=([^&]*))?`;
            } else {
              return index === 0 ? `${name}=([^&]*)` : `&${name}=([^&]*)`;
            }
          })
          .join('')}?$`
      : '';

    const regexPattern = `${regexPath}${regexQuery}$`;

    return RE2JS.compile(regexPattern);
    // return new RE2JS(regexPattern);
  });

  let isValid = regexPatterns.some((regex) => {
    return regex.matcher(normalizedUrl).find();
  });

  if (!isValid) {
    const idRegexPatterns = ALLOWED_URL_PATTERNS_WITH_REGULAR_ID.map((pattern) => {
      const regexPattern = pattern.replace(/:id/g, ID_PATTERN);

      return RE2JS.compile(`^${regexPattern}$`);

      // return new RE2JS(`^${regexPattern}$`);
    });
    isValid = idRegexPatterns.some((regex) => regex.matcher(normalizedUrl).find());
  }

  return isValid;
}
